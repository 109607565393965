<template>

    <div @click="doMenu()" class="
    fixed bottom-0 right-0 shadow-2xl
    text-sm uppercase bg-gradient-to-b text-center from-slate-700 to-slate-800 h-16 border-t border-slate-600
    rounded-md hover:bg-slate-600 z-50
    flex flex-col items-center justify-center
    w-1/5 p-2
    ">
            <div class="m-2 h-7"><img src="@/assets/icon/close.svg" class="w-6 h-6 mx-auto"></div>
            <div>Close</div>
    </div>

    <div class="w-full z-20 bg-slate-800 h-screen overflow-y-auto overflow-x-hidden fixed 
    md:w-[80%] min-h-screen md:max-w-[700px] mx-auto md:border-l md:border-r md:border-slate-700
    ">
        <!--   -->
        <div class="flex h-12 flex-row bg-gradient-to-b from-slate-700 to-slate-800 shadow-slate-950 shadow-lg border-b border-slate-600">
            <div class="flex-grow uppercase my-auto ts1 text-slate-400 text-base pl-3">
                <div class="mx-auto">Main Menu</div>
                <!-- <img src="@/assets/pics/donton.png" class="w-16 mx-auto"> -->
            </div>
            <div class="w-12 h-12 p-3" @click="doMenu()"><img src="@/assets/icon/close.svg" class="h-6 w-6 cursor-pointer"></div>
        </div>
        <div class="flex flex-col gap-3 p-3">

            <div class="border border-slate-600 rounded-md shadow-slate-950 shadow-lg">

                <div class="text-sm p-2 uppercase text-slate-400">General</div>
                <!-- <router-link :to="{ name: 'weap' }" class="border-t border-slate-600 p-2 flex flex-row gap-2"></router-link>
                    <img src="@/assets/set/ammo.svg" class="w-8 h-8 my-auto">
                    <div class="my-auto">
                        <div>Armory</div>
                        <div class="text-sm text-slate-400">Manage Weapons</div>
                    </div>
                </router-link> -->

                <router-link 
                 v-for="(menuItem, index) in mainMenu" 
                 :to="{ name: menuItem.route }"
                 class="border-t border-slate-600 p-2 flex flex-row gap-2 hover:bg-slate-700"
                 @click="doMenu()"
                 :key="index"
                 >
                    <img :src="getImage(menuItem.icon)" class="w-8 h-8 my-auto mr-2">
                    <div class="my-auto">
                        <div>{{ menuItem.name }}</div>
                        <div class="text-sm text-slate-400">{{ menuItem.description }}</div>
                    </div>
                </router-link>
                    
            </div>

            <div v-show="TutorialStep==9" class="z-[42] border border-slate-600 bg-slate-950 bg-opacity-90 shadow-slate-950 shadow-xl p-3 rounded-md top-[620px]">
                <div class="relative">
                    <div class="absolute top-[80px] left-[0px]"><img src="@/assets/pics/arr.png" class="w-[87px]"></div>
                    <div class="flex flex-row gap-3">
                        <div class="shrink-0 z-[55]">
                            <img src="@/assets/pics/tutor.png" class=" w-12 h-12 border border-slate-500 rounded-full">
                        </div>
                        <div class="flex-grow ml-4">
                            <div class="font-bold">Grow Your Gang!</div>
                            <div class="text-sm pb-1">Each Gangster takes 2 Weapons in fight.</div>
                        </div>
                        <div class="ml-auto flex flex-col gap-2">
                            <button class="ml-auto but-5 but-b text-sm opacity-40" @click="closeTutorial">X</button>
                        </div>
                    </div>
                </div>
            </div> 

            <div class="border border-slate-600 rounded-md shadow-slate-950 shadow-lg">

                <div class="text-sm p-2 uppercase text-slate-400">Gang Members</div>

                <router-link 
                v-for="(menuItem, index) in gangMenu" 
                :to="{ name: menuItem.route }"
                class="border-t border-slate-600 p-2 flex flex-row gap-2 hover:bg-slate-700"
                @click="doMenu(menuItem.route)"
                :key="index"
                >
                    <img :src="getImage(menuItem.icon)" class="w-8 h-8 my-auto mr-2">
                    <div class="my-auto">
                        <div>{{ menuItem.name }}</div>
                        <div class="text-sm text-slate-400">{{ menuItem.description }}</div>
                    </div>
                </router-link>
                
            </div>

            <!-- <div class="border border-slate-600 rounded-md shadow-slate-950 shadow-lg">
                <div class="text-sm p-2 uppercase text-slate-400">Profile</div>
                <div @click="goProfile()"
                class="w-full border-t border-slate-600 p-2 flex flex-row gap-2 hover:bg-slate-700 text-left cursor-pointer"
                >

                    <img src="@/assets/icon/wallet.svg" class="w-8 h-8 my-auto mr-2">
                    <div class="my-auto">
                        <div>Profile</div>
                        <div class="text-sm text-slate-400">Manage your TON wallet</div>
                    </div>

                    <div class="ml-auto shrink-0 flex flex-row gap-2 bg-slate-700 rounded-md my-1 p-2 w-fit px-3">
                        <img src="@/assets/icon/ton.svg" class="w-5">
                        <div class="">{{ Data.user_data.user_ton }}</div>
                    </div>
                </div>
                
            </div> -->

            <!-- <router-link 
                :to="{ name: 'settings' }"
                class="border-t border-slate-600 p-2 flex flex-row gap-2 hover:bg-slate-700"
                @click="doMenu()"
                > -->
                <!-- </router-link> -->

            <!-- <panelGems :Data="Data" :DEBUG="DEBUG" /> -->
            <div class="border border-slate-600 rounded-md overflow-hidden relative shadow-slate-950 shadow-lg">
                <div class="b-b-400 absolute left-[150px]"></div>
                <div class="relative p-3">
                    
                    <div class="text-sm mb-3 uppercase text-slate-400"><img src="@/assets/pics/gem.png" class="w-5 inline-block"> Gems</div>
                    
                    <div class="flex flex-row gap-2 border border-slate-500 rounded-md my-1 py-2 mb-2 w-fit px-3">
                        You Have
                        <div class="font-bold">{{ Data.user_data.user_gems }}</div>
                        <img src="@/assets/pics/gem.png" class="w-5">
                        Gems
                    </div>

                    <div class="mb-3">
                        <div class="mb-2">Use Gems to unlock:</div>
                        <ul>
                            <li class="list-disc">VIP Status benefits</li>
                            <li class="list-disc">Unique Weapons</li>
                            <li class="list-disc">Boosters for extra Cash and EXP</li>
                            <li class="list-disc">Instant HP, Energy and Stamina restore</li>
                            <!-- <li>Unlocks TON Collection from Treeasury</li> !IMPORTANT -->
                        </ul>
                    </div>
                    <div class="mb-1">
                        <router-link @click="doMenu()" :to="{ name: 'shop' }" class="but-c but-4">Gems Shop</router-link>
                    </div>
                </div>
            </div>

            <div class="border border-slate-600 rounded-md shadow-slate-950 shadow-lg">

                <div class="text-sm p-2 uppercase text-slate-400">Operations</div>
                <!-- <router-link :to="{ name: 'weap' }" class="border-t border-slate-600 p-2 flex flex-row gap-2"></router-link>
                    <img src="@/assets/set/ammo.svg" class="w-8 h-8 my-auto">
                    <div class="my-auto">
                        <div>Armory</div>
                        <div class="text-sm text-slate-400">Manage Weapons</div>
                    </div>
                </router-link> -->

                <router-link 
                v-for="(menuItem, index) in gemsMenu" 
                :to="{ name: menuItem.route }"
                class="border-t border-slate-600 p-2 flex flex-row gap-2 hover:bg-slate-700"
                @click="doMenu()"
                :key="index"
                >
                    <img :src="getImage(menuItem.icon)" class="w-8 h-8 my-auto mr-2">
                    <div class="my-auto">
                        <div>{{ menuItem.name }}</div>
                        <div class="text-sm text-slate-400">{{ menuItem.description }}</div>
                    </div>
                </router-link>
                    
            </div>

            <div class="border border-slate-600 rounded-md  shadow-slate-950 shadow-lg p-3">
                <div class="text-sm mb-3 uppercase text-slate-400">Guidelines</div>
                <div class="mb-3">
                    Larn all the details about the game:
                </div>
                <div class="mb-1">
                    <router-link @click="doMenu()" :to="{ name: 'guide-intro' }" class="but-4 but-c">
                        <img src="@/assets/icon/book-b.svg" class="w-5 mr-2 inline-block">
                        Guidelines</router-link>
                </div>
            </div>

            <div class="border border-slate-600 rounded-md  shadow-slate-950 shadow-lg p-3">
                <div class="text-sm mb-3 uppercase text-slate-400">Settings</div>
                <div class="mb-3">
                    Change your gang name and languange:
                </div>
                <div class="mb-1 flex flex-row gap-2">
                    <router-link @click="doMenu()" :to="{ name: 'settings' }" class="but-4 but-c text-white">
                        <img src="@/assets/icon/set.svg" class="w-5 mr-2 inline-block">
                        Settings</router-link>
                    <router-link @click="doMenu()" :to="{ name: 'info' }" class="but-4 but-b text-white ml-auto">
                        Statistics</router-link>
                </div>
            </div>

            <div class="border border-slate-600 rounded-md  shadow-slate-950 shadow-lg p-3">
                <div class="text-sm mb-3 uppercase text-slate-400">Community</div>
                <div class="mb-1">
                    <div class="mb-2">Join DeGangs social networks:</div>
                    <div class="flex flex-col gap-2">
                        <!-- <a href="https://t.me/don_ton_chat" class="mt-2 but-4 but-a w-fit">
                            <img src="@/assets/icon/social/t.svg" class="w-5 mr-2 inline-block">
                            Chat Group
                        </a> -->
                        <a href="https://t.me/de_gangs" class="mt-2 but-4 but-a w-fit">
                            <img src="@/assets/icon/social/t.svg" class="w-5 mr-2 inline-block">
                            News Channel
                        </a>
                        <!-- <div class="mt-2 but-4 but-a w-fit">
                            <img src="@/assets/icon/social/x.svg" class="w-5 mr-2 inline-block">
                            <a href="">Follow us on X</a>
                        </div> -->
                        <a href="http://www.degangs.com" target="_new" class="mt-2 but-4 but-a w-fit">
                            Official Website
                        </a>
                    </div>
                </div>
                
            </div>

            <div class="text-sm text-slate-400 my-4 mx-3">
                By playing the game and/or using the DeGangs services you <span @click="doDEBUG()">agree</span> to the 
                <a href="https://degangs.com/terms" target="_blank" class="read-more text-slate-400">Terms & Conditions</a>
            </div>

        </div>
    </div>
</template>

<script>
// import panelGems from '@/blocks/Gems.vue';

  export default {
    props: {
        Data: Object,
        DEBUG: Boolean,
        TutorialShow: Boolean,
        TutorialStep: Number,
    },
    components: {
        // panelGems
    },
    data() {
        return {
            userData: {},

            mainMenu: [
                {
                    route: 'home',
                    name: 'Gang',
                    description: 'Status of your gang',
                    icon: 'set/user.svg'
                },
                {
                    route: 'quest',
                    name: 'Jobs',
                    description: 'Do daily missions for EXP and Cash',
                    icon: 'set/signal.svg'
                },
                {
                    route: 'estate',
                    name: 'Properties',
                    description: 'Invest in Real Estate to increase income',
                    icon: 'set/estate.svg'
                },

                {
                    route: 'armory',
                    name: 'Armory',
                    description: 'Manage your weapons and upkeep',
                    icon: 'set/ammo.svg'
                },


                {
                    route: 'travel',
                    name: 'Travel',
                    description: 'Go to other cities for more powerful weapons and higer rewards',
                    icon: 'set/plane.svg'
                },
           

            ],

            gangMenu: [
                {
                    route: 'train',
                    name: 'Gangsters',
                    description: 'Train your Gangsters for HP and Power',
                    icon: 'icon/gang.svg'
                },
                {
                    route: 'invite',
                    name: 'Invite',
                    description: 'Recruit new Gangsters',
                    icon: 'set/radio.svg'
                },
                {
                    route: 'merc',
                    name: 'Mercenaries',
                    description: 'Hire Mercenaries to fight for your gang',
                    icon: 'icon/merc.svg'
                },
                {
                    route: 'fight',
                    name: 'Attack',
                    description: 'Fight rival gangs to collect Cups',
                    icon: 'set/gun.svg'
                },
                {
                    route: 'weapons',
                    name: 'Weapons',
                    description: 'Your weapons in all the cities',
                    icon: 'set/bullet.svg'
                },
                {
                    route: 'power',
                    name: 'Power',
                    description: 'Check Gang total power',
                    icon: 'pics/bang.png'
                },

            ],

            gemsMenu: [

                 {
                    route: 'restore',
                    name: 'Restore',
                    description: 'Restore HP, Energy and Stamina',
                    icon: 'set/hp.svg'
                },
                // {
                //     route: 'shop',
                //     name: 'Shop',
                //     description: 'Get Gems to unlock benefits',
                //     icon: 'pics/gem.png'
                // },
                {
                    route: 'rank',
                    name: 'Rankings',
                    description: 'Top Gagns with Cups',
                    icon: 'set/rank.svg'
                },
                {
                    route: 'achieve',
                    name: 'Achievements',
                    description: 'Instant Cash rewards',
                    icon: 'icon/flag.svg'
                },
                {
                    route: 'boost',
                    name: 'Boosters',
                    description: 'Increase crucial parameters',
                    icon: 'icon/boost.svg'
                },
                {
                    route: 'vip',
                    name: 'VIP',
                    description: 'Unlock priveledges with VIP',
                    icon: 'icon/vip.svg'
                },


   
  
            ],

        }
    },
    mounted() {
        this.userData = this.Data.user_data;
    },
    methods: {
        doMenu(item_name) {
            // alert('d='+this.TutorialShow);
            if(item_name=='invite' && this.TutorialShow) { this.$emit('doTutorial', 10); }
            this.$emit('doMenu');
        },
        goProfile() {
            this.$emit('doUserProfile');
            this.$emit('doMenu');
        },
        doDEBUG() {
            this.$emit('doDEBUG');
        }
    }
}
</script>